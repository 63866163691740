<template>
  <div id="app" style="height: auto; min-height: 100vh; padding-bottom: 8px;">
    <div v-if="data && data.tracingId && data.tracingId !== ''">
      <div class="header">
        <div class="text">
          <div style="color: #FFF;">{{ '您查询的溯源码（共查询 ' + data.scanNumber + ' 次）' }}</div>
          <div style="color: #FFF; font-size: 18px;">{{ data.tracingNo }}</div>
        </div>
        <div class="info">
          <div class="info_top">
            <div style="display: flex;">
              <van-image :src="data.productThumbnailImageUrl" alt="" style="width: 80px; height: 80px;"/>
              <div style="flex: 1; margin-left: 10px;">
                <div style="font-size: 16px; color: #222222; font-weight: bold; line-height: 20px; ">{{
                    data.productName
                  }}
                </div>
                <div style="font-size: 12px;display: flex; color: #A1A1A1; margin-top: 6px; line-height: 14px;">
                  预计养殖天数:
                  <div style="margin-left: 8px;">{{ getExpectBreedingDay(data.expectBreedingDay) }}</div>
                </div>
                <div style="font-size: 12px;display: flex; color: #A1A1A1; margin-top: 6px; line-height: 14px;">
                  批次数量:
                  <div style="margin-left: 8px;">{{ data.breedingQty + ' 只' }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="info_bottom">
            <!-- <img src="../assets/images/icon_true2.png" style="width: 64px; height: 64px;" /> -->
            <div style="flex: 1; margin-left: 18vw;">
              <div style="font-size: 16px; color: #986d3f; font-weight: bold; line-height: 16px; ">{{ '真品验证通过！' }}</div>
              <div
                  style="display: flex; color: #996e41; margin-top: 12px; font-size: 12px; line-height: 12px; width: calc(100vw - 132px)">
                <div style="width: 60px;">唯一标识:</div>
                <div
                    style="flex: 1; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; word-break: break-all;">
                  {{ data.manufactureOrderContractId }}
                </div>
              </div>
              <div style="display: flex; color: #996e41; margin-top: 8px; font-size: 12px; line-height: 12px;">
                <div style="width: 60px;">批次编号:</div>
                <div>{{ data.breedingBatchCode }}</div>
              </div>
            </div>

          </div>

          <div class="fourTab">
            <ul class="clear">
              <li :class="[tabIndex === 0 ? 'now' : '']" @click="onClickChangeTab(0)">产品信息</li>
              <li :class="[tabIndex === 1 ? 'now' : '']" @click="onClickChangeTab(1)">全流程溯源</li>
              <li :class="[tabIndex === 2 ? 'now' : '']" @click="onClickChangeTab(2)">出品企业</li>
              <li :class="[tabIndex === 3 ? 'now' : '']" @click="onClickChangeTab(3)">原产地信息</li>
            </ul>
          </div>

        </div>
      </div>

      <div class="tabContainer">
        <div v-show="tabIndex === 0">
          <HomeProductComponent ref="homeProductComponent" :data="productData"></HomeProductComponent>
        </div>
        <div v-show="tabIndex === 1">
          <HomeProductTraceComponent ref="homeProductTraceComponent" :total-stage-num="totalStepNumber"
                                     :first="data.first" :stage-list="data.stageList"></HomeProductTraceComponent>
        </div>
        <div v-if="tabIndex === 2">
          <HomeBusinessInfoComponent ref="homeBusinessInfoComponent" :business="business"></HomeBusinessInfoComponent>
        </div>
        <div v-show="tabIndex === 3">
          <HomeOriginInfoComponent ref="homeOriginInfoComponent" :source-image="this.data.sourceImage"
                                   :source-introduction="data.sourceIntroduction"
                                   :source-play-address-url="data.sourcePlayAddressUrl"
                                   :source-address="data.sourceAddress" :latitude="data.latitude"
                                   :longitude="data.longitude">
          </HomeOriginInfoComponent>
        </div>
      </div>
    </div>

    <div v-if="!(data && data.tracingId && data.tracingId !== '') && !firstLoad" style="padding-top: 8px;">
      <div
          style="background: #FFF; border-radius: 8px; padding: 8px 8px 8px 8px; margin: 0px 8px 8px 8px; height: calc(100vh - 24px)">
        <van-empty class="custom-image" image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
                   description="溯源码异常，请重新扫码进入" style="height: 100%"/>
      </div>
    </div>

    <van-dialog v-model="show" confirm-button-text="关闭" confirm-button-color="#A1A1A1" @opened="onClickShowSteps">
      <div style="margin: 16px; font-weight: bold; text-align: center;">近七天步数图</div>
      <div id="container" style="height: 280px;"></div>
    </van-dialog>

    <van-dialog v-model="showFeedingRecord" confirm-button-text="关闭" confirm-button-color="#A1A1A1">
      <div style="margin: 16px; font-weight: bold; text-align: center;">饲养记录</div>
      <div style="height: 66vh; overflow-y: scroll; padding: 0 8px; font-size: 14px;">
        <van-list v-model="loading" :finished="true" v-if="feedingRecordList.length > 0">
          <div style="display: flex; width: 100%;" v-for="(item, index) in feedingRecordList" :key="index">
            <div style="flex: 1; border-bottom: 0.5px solid rgb(229, 229, 229); padding: 8px; margin: 4px 0;">
              <div style="display: flex; color: #A1A1A1;">
                <div class="text-bgc">饲养时间</div>
                <div style="flex: 1; color: #34AC40; font-weight: bold; padding: 5px 0 5px 8px;margin: auto 0;">
                  {{ getFeedingTime(item.feedingTime) }}
                </div>
              </div>
              <div style="display: flex; color: #A1A1A1; margin-top: 10px;justify-content: space-between;">
                <div>投喂饲料</div>
                <div style="color: #222222; margin-left: 8px;">{{ item.feedingFood }}</div>
              </div>
              <div style="display: flex;justify-content: space-between; color: #A1A1A1; margin-top: 10px;">
                <div>投喂频次</div>
                <div style="color: #222222; margin-left: 8px;">{{ getFeedingFrequency(item.feedingFrequency) }}</div>
              </div>
            </div>
          </div>

        </van-list>
        <van-empty class="custom-image" image="https://img01.yzcdn.cn/vant/custom-empty-image.png" description="暂未上传数据"
                   style="height: 100%" v-if="feedingRecordList.length <= 0"/>
      </div>
    </van-dialog>

    <van-dialog v-model="showVaccinumRecord" confirm-button-text="关闭" confirm-button-color="#A1A1A1">
      <div
          style="margin: 16px 0 0; padding: 0 0 16px 0; border-bottom: 0.5px solid rgb(229, 229, 229);color: #999; text-align: center;">
        防疫记录
      </div>
      <div style="height: 66vh; overflow-y: scroll; padding: 0 8px; font-size: 14px;">
        <van-list v-model="loading" :finished="true" v-if="vaccinumRecordList.length > 0">
          <div style="display: flex; width: 100%;" v-for="(item, index) in vaccinumRecordList" :key="index">
            <div style="flex: 1; border-bottom: 0.5px solid rgb(229, 229, 229); padding: 8px; margin: 4px 0;">
              <div style="display: flex; color: #A1A1A1;">
                <div class="text-bgc">注射时间</div>
                <div style="flex: 1; color: #34AC40; font-weight: bold; padding: 5px 0 5px 8px;margin: auto 0;">
                  {{ getTime(item.vaccineTime) }}
                </div>
              </div>
              <div style="display: flex; color: #A1A1A1; margin-top: 10px;justify-content: space-between;">
                <div>疫苗类型</div>
                <div style="color: #222222; margin-left: 8px;">{{ item.traceVaccineName }}</div>
              </div>
              <div style="display: flex;justify-content: space-between; color: #A1A1A1; margin-top: 10px;">
                <div>注射情况</div>
                <div style="color: #222222; margin-left: 8px;">{{ item.traceVaccineDosage }}</div>
              </div>
            </div>
          </div>

        </van-list>
        <van-empty class="custom-image" image="https://img01.yzcdn.cn/vant/custom-empty-image.png" description="暂未上传数据"
                   style="height: 100%" v-if="vaccinumRecordList.length <= 0"/>
      </div>
    </van-dialog>

    <van-dialog v-model="showBusiness" confirm-button-text="关闭" confirm-button-color="#A1A1A1"
                style="background: #34AC40;">
      <div style="margin: 16px; font-weight: bold; text-align: center; color: #FFFFFF;">{{ data ? data.supplierName : '' }}</div>
      <div style="display: flex; color: #A1A1A1; margin-top: 4px;" v-if="data">
        <div
            style="display:flex; flex: 1; color: #222222; margin-left: 8px; align-items: center; place-content: center;">
          <div v-for="(item, index) in data.trustList" :key="index" style="display: flex;"
               :style="{ marginLeft: index !== 0 ? `10px` : `0` }">
            <img src="../assets/images/icon_trust.png" style="width: 18px; height: 18px;"/>
            <div style="line-height: 18px;">{{ '认真' }}</div>
          </div>
        </div>
      </div>
      <div
          style="height: 55vh; overflow-y: scroll; font-size: 14px; padding: 0 8px 12px; background: linear-gradient(360deg, #F4F6F8 0%, #34AC40 100%);">
        <div class="box" style="margin: 0;">
          <div style="color: #222222; font-weight: bold;">
            <div style="display: flex;">
              <img src="../assets/images/bias-double-dot.png" style="width: 18px; height: 18px;"/>
              <div style="line-height: 18px; margin-left: 5px;">企业简介</div>
            </div>
            <van-image width="100%" height="22vh" :src="business ? business.imageUrl : ''" style="margin-top: 8px;"/>
            <div style="color: #222222; font-size: 14px; margin-top: 8px; font-weight: normal;">{{
                business.introduction
              }}
            </div>
          </div>
        </div>
        <div class="box">
          <div style="color: #222222; font-weight: bold;">
            <div style="display: flex;">
              <img src="../assets/images/bias-double-dot.png" style="width: 18px; height: 18px;"/>
              <div style="line-height: 18px; margin-left: 5px;">企业荣誉</div>
            </div>
            <div v-for="(item, index) in business.list" :key="index">
              <van-image width="100%" height="22vh" :src="item" style="margin-top: 8px;"/>
            </div>

          </div>

        </div>
        <div class="box">
          <div style="color: #222222; font-weight: bold; margin-bottom: 12px;">
            <div style="display: flex;">
              <img src="../assets/images/bias-double-dot.png" style="width: 18px; height: 18px;"/>
              <div style="line-height: 18px; margin-left: 5px;">定位信息</div>
            </div>
            <div id="businessMap" style="width: 100%; height: calc(320px / 16 * 9); margin-top: 8px;"></div>

            <div style="display: flex; color: #222222; font-weight: normal; margin-top: 8px;">
              <div style="width: 42px;">{{ '地址:' }}</div>
              <div style="flex: 1;">{{ business.address }}</div>
            </div>

          </div>
        </div>
        <div class="box">
          <div style="display: flex;">
            <img src="../assets/images/bias-double-dot.png" style="width: 18px; height: 18px;"/>
            <div style="line-height: 18px; margin-left: 5px; font-weight: bold">区块链证书展示</div>
          </div>

          <div style="display: flex; margin-top: 4px; align-items: center; color: #222222;">
            <van-image :src="business ? business.supplierContractUrl : ''" alt=""
                       style="width: 160px; height: 240px; margin-left: 72px;"/>
          </div>

          <div style="color: #A1A1A1; margin-top: 8px;">
            <div style="display: flex; margin-top: 4px;">
              <div style="width: 56px;">唯一标识</div>
              <div style="flex: 1; color: #222222; margin-left: 8px; text-align: right; word-break: break-all;">
                {{ business.supplierContractId }}
              </div>
            </div>
            <div style="display: flex; margin-top: 4px;">
              认证时间
              <div style="flex: 1; color: #222222; margin-left: 8px; text-align: right;">
                {{ business.supplierDigitalIdentityReportTime }}
              </div>
            </div>
          </div>
        </div>

      </div>
    </van-dialog>

    <van-dialog v-model="showFarm" confirm-button-text="关闭" confirm-button-color="#A1A1A1"
                style="background: #34AC40;">
      <div style="margin: 16px; font-weight: bold; text-align: center; color: #FFFFFF;">{{ farm ? farm.name : '' }}</div>
      <div style="height: 55vh; overflow-y: scroll; padding: 0 8px; font-size: 14px; background: linear-gradient(360deg, #F4F6F8 0%, #34AC40 100%);">
        <div class="box" style="margin-top: 0;">
          <div style="display: flex;">
            <img src="../assets/images/bias-double-dot.png" style="width: 18px; height: 18px;"/>
            <div style="line-height: 18px; margin-left: 5px; font-weight: bold">基础信息</div>
          </div>

          <div style="flex: 1; color: #A1A1A1;">
            <div style="display: flex; margin-top: 4px;">
              养殖容量
              <div style="flex: 1; color: #222222; margin-left: 8px; text-align: right;">{{ farm.volume }}</div>
            </div>
            <div style="display: flex; margin-top: 4px;">
              圈
              <div style="width: 7px"/>
              舍
              <div style="width: 7px"/>
              数
              <div style="flex: 1; color: #222222; margin-left: 8px; text-align: right;">{{ farm.circleHouse }}个</div>
            </div>
            <div style="display: flex; margin-top: 4px;">
              面积(m²)
              <div style="flex: 1; color: #222222; margin-left: 8px; text-align: right;">{{ farm.acreage }}</div>
            </div>
          </div>
        </div>
        <div class="box">
          <div style="color: #222222; font-weight: bold; margin-top: 8px;">
            <div style="display: flex;">
              <img src="../assets/images/bias-double-dot.png" style="width: 18px; height: 18px;"/>
              <div style="line-height: 18px; margin-left: 5px; font-weight: bold">养殖场简介</div>
            </div>
            <van-image width="100%" height="22vh" :src="farm ? farm.imageUrl : ''" style="margin-top: 8px;"/>
            <div style="color: #222222; font-size: 14px; margin-top: 8px; font-weight: normal;">{{
                farm.introduction
              }}
            </div>
          </div>
        </div>
        <div class="box">
          <div style="color: #222222; font-weight: bold; margin-top: 8px; margin-bottom: 12px;">
            <div style="display: flex;">
              <img src="../assets/images/bias-double-dot.png" style="width: 18px; height: 18px;"/>
              <div style="line-height: 18px; margin-left: 5px; font-weight: bold">定位信息</div>
            </div>
            <div id="farmMap" style="width: 100%; height: calc(320px / 16 * 9); margin-top: 8px;"></div>

            <div style="display: flex; color: #222222; font-weight: normal; margin-top: 8px;">
              <div style="width: 42px;">{{ '地址:' }}</div>
              <div style="flex: 1;">{{ farm.address }}</div>
            </div>

          </div>
        </div>

      </div>
    </van-dialog>

    <van-dialog v-model="showOrderContractImage" confirm-button-text="X" confirm-button-color="#A1A1A1">
      <van-image :src="(data && data.manufactureOrderContractUrl) ? data.manufactureOrderContractUrl : ''" alt=""
                 style="width: 320px; height: 480px;"/>
    </van-dialog>

    <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{ '加载中' }}</van-loading>
    </div>

  </div>
</template>

<script>
import Vue from "vue";
import HomeProductTraceComponent from "../components/HomeProductTraceComponent";
import HomeOriginInfoComponent from "../components/HomeOriginInfoComponent";
// import {Chart} from '@antv/g2';
import {Toast,Image as VanImage ,Empty,Dialog,List,Loading} from "vant";
import {post} from "../utils/http";
import {getDiffDay} from "../utils/util";
import moment from "moment";
import HomeProductComponent from "../components/HomeProductComponent";
import HomeBusinessInfoComponent from "../components/HomeBusinessInfoComponent";
Vue.use(Toast).use(VanImage).use(Empty).use(Dialog).use(List).use(Loading)
export default {
  name: "home",
  components: {
    HomeBusinessInfoComponent,
    HomeProductComponent,
    HomeProductTraceComponent,
    HomeOriginInfoComponent,
    
  },
  data() {
    return {
      firstLoad: true,
      traceCode: '',
      data: null,
      totalStepNumber: 0,
      showOrderContractImage: false,

      sourceImage: '',
      sourceIntroduction: '',

      loadingAll: false,
      loading: false,
      tabIndex: 0,

      show: false,
      chart: null,//图表对象
      showData: [//图表中将要显示的数据

      ],

      showFeedingRecord: false,
      feedingRecordList: [],

      showVaccinumRecord: false,
      vaccinumRecordList: [],

      showBusiness: false,
      business: {
        title: '文昌鸡原产地简介',
        url: '',
        content: '安静的歌i加上见到过i哦啊手机导购i啊阿杰地阿佛第四届高is的架构as低结构i阿杰地宫颈癌搜地更煎熬is的警告是第几个',
        list: [{}, {}]
      },
      businessMap: null,
      businessMarkerLayer: null,

      showFarm: false,
      farm: {},
      farmMap: null,
      farmMarkerLayer: null,

      productData: {}

    }
  },

  mounted() {
    console.log(555);
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }

    this.traceCode = this.$route.query.traceCode;
    if (this.traceCode && this.traceCode !== '') {
      this.requestData();
    }

  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },

  methods: {
    goBack() {
      history.pushState(null, null, document.URL);
    },

    onClickChangeTab(tabIndex) {
      if (tabIndex === this.tab) {
        return;
      }
      this.tabIndex = tabIndex;

      if (this.tabIndex === 2) {
        this.$nextTick(() => {
          this.$refs.homeBusinessInfoComponent.init();
        })
      }

      if (this.tabIndex === 3) {
        this.$nextTick(() => {
          this.$refs.homeOriginInfoComponent.init();
        })
      
      }

    },
    onClickShow() {
      this.show = true;
    },

    //创建chart
    createChart() {
      if (!this.chart) {
        this.chart = new Chart({
          container: 'container',//chart容器id
          autoFit: false,//图表是否自适应容器宽高，默认为 false
          width: 320,//图标宽度
          height: 280,//图表高度
          padding: [40, 40, 40, 40],//图表内边距,依次为:上，右，下，左
          // defaultInteractions:"ellipsis-text",//配置图表默认交互，仅支持字符串形式。G2 默认支持的交互有 'tooltip', 'legend-filter', 'legend-active', 'continuous-filter', 'ellipsis-text'
          pixelRatio: window.devicePixelRatio,//设置设备像素比，默认取浏览器的值 window.devicePixelRatio
          renderer: "canvas",//指定渲染引擎，默认使用 canvas。可选：'canvas' | 'svg'
          visible: true,//chart 是否可见，默认为 true，设置为 false 则会隐藏。
        });
      }

      this.chart.source(this.showData);
      // 在x*y的坐标点上按z值绘制线条, 如果z值相同将使用直线连接
      this.chart.line().position('stepDate*当日步数').color('#34AC40');
      // 在x*y的坐标上按z值绘制圆点
      this.chart.point().position('stepDate*当日步数').size(4).color('#34AC40').shape('circle');
      this.chart.render();
    },

    requestData() {
      this.loadingAll = true;
      const url = '/api/public/v2/manufacture/tracing/{uniqueCode}/findTrace'.replace('{uniqueCode}', this.traceCode);
      post(url, {}, false)
          .then(res => {
            this.traceCode = res.data;
            this.requestDataAll();
          })
          .catch(err => {
            this.requestDataAll();
          })
          .finally(() => {
            this.loadingAll = false;
            this.firstLoad = false;
          })
    },
    requestDataAll() {
      const url = '/api/public/v2/manufacture/tracing/{tracingNo}/traceCodeScanInfo'.replace('{tracingNo}', this.traceCode);
      post(url, {}, false)
          .then(res => {
            this.data = res.data;
            if(!this.data.manufactureOrderContractId || this.data.manufactureOrderContractId === '') {
              this.data.manufactureOrderContractId = '52f2275c2a758ea6b1e03ba45e9c5bb8020361fc8827b5f2bad7b5a64028deb086aa91ce8c3f7cc6ed15c29403ee9facf1a5ac1a93375ed86ce02ff73be0b232';
              this.data.manufactureOrderContractUrl = 'https://asset.fsytss.com/srm-order/prod//2024/07/5ef55a41254248e48ebcc409ba73f230.jpg';
              this.data.manufactureOrderDigitalIdentityReportTime = 1720578927000;
            }

            this.onClickShowBusiness();

            this.sourceImage = this.data.sourceImage;
            this.sourceIntroduction = this.data.sourceIntroduction;

            let firstTrustList = [];
            if (this.data.supplierAuthChecked1 === 1) {
              firstTrustList.push({
                name: this.data.supplierAuth1
              });
            }
            if (this.data.supplierAuthChecked2 === 1) {
              firstTrustList.push({
                name: this.data.supplierAuth2
              });
            }
            if (this.data.supplierAuthChecked3 === 1) {
              firstTrustList.push({
                name: this.data.supplierAuth3
              });
            }

            this.data.first = {
              name: this.data.supplierName,
              trustList: firstTrustList
            }

            let nowDate = new Date(moment((new Date()).getTime()).format('yyyy/MM/DD') + " 23:59:59");
            let stageList = [];
            for (let breedingBatchStageIndex in this.data.breedingBatchStageList) {
              let breedingBatchStage = this.data.breedingBatchStageList[breedingBatchStageIndex];
              let startTime = breedingBatchStage.startTime ? new Date(moment(breedingBatchStage.startTime).format('yyyy/MM/DD') + " 00:00:00") : null;
              let endTime = breedingBatchStage.endTime ? new Date(moment(breedingBatchStage.endTime).format('yyyy/MM/DD') + " 23:59:59") : null;

              let cycleDay = 0;
              if (startTime && startTime <= nowDate.getTime()) {
                if (endTime && endTime > 0 && endTime <= nowDate.getTime()) {
                  cycleDay = getDiffDay(startTime, endTime);
                } else {
                  cycleDay = getDiffDay(startTime, nowDate);
                }
              }

              stageList.push({
                id: breedingBatchStage.id,
                stage: breedingBatchStage.stage,
                breedingFarmer: breedingBatchStage.breedingFarmer,
                traceAreaName: breedingBatchStage.traceAreaName,
                thumbnailImageUrl: breedingBatchStage.thumbnailImageUrl,
                cycleDay: cycleDay,
                step: breedingBatchStage.step,
                farmManagerId: breedingBatchStage.farmManagerId,
                farmManagerName: breedingBatchStage.farmManagerName,
                farmManagerCircleHouse: breedingBatchStage.farmManagerCircleHouse,
                cycle: moment(breedingBatchStage.startTime).format('yyyy-MM-DD') + "至" + moment(breedingBatchStage.endTime).format('yyyy-MM-DD')
              })
            }

            let cycleDay = 0;
            stageList.forEach(stage => {
              cycleDay += stage.cycleDay;
            })

            if (this.data.first) {
              this.totalStepNumber++;
            }
            if (stageList.length > 0) {
              this.totalStepNumber += stageList.length;
            }

            this.data.stageList = stageList;

            this.productData = {
              cycleDay: cycleDay,
              steps: this.data.steps,
              supplierName: this.data.supplierName,
              productSpec: this.data.productSpec,
              manufactureOrderContractId: this.data.manufactureOrderContractId,
              manufactureOrderDigitalIdentityReportTime: moment(this.data.manufactureOrderDigitalIdentityReportTime).format('yyyy-MM-DD HH:mm:ss'),
              manufactureOrderContractUrl: this.data.manufactureOrderContractUrl

            };

          })
          .catch(err => {
            Toast(err.message);
          })
          .finally(() => {
            this.loadingAll = false;
            this.firstLoad = false;
          })
    },

    onClickShowFeedingRecord(stageId) {
      this.loadingAll = true;
      const url = '/api/public/v2/manufacture/tracing/{stageId}/stageItemInfo'.replace('{stageId}', stageId);
      post(url, {}, false)
          .then(res => {
            this.feedingRecordList = res.data.breedingBatchStageFeedingList;

            this.showFeedingRecord = true;
          })
          .catch(err => {
            Toast(err.message);
          })
          .finally(() => {
            this.loadingAll = false;
          })
    },
    onClickShowVaccinumRecord(stageId) {
      this.loadingAll = true;
      const url = '/api/public/v2/manufacture/tracing/{stageId}/stageItemInfo'.replace('{stageId}', stageId);
      post(url, {}, false)
          .then(res => {
            this.vaccinumRecordList = res.data.breedingBatchStageVaccineList;

            this.showVaccinumRecord = true;
          })
          .catch(err => {
            Toast(err.message);
          })
          .finally(() => {
            this.loadingAll = false;
          })
    },
    onClickShowBusiness() {
      // this.loadingAll = true;
      const url = '/api/public/v2/manufacture/tracing/{businessId}/businesAuthInfo'.replace('{businessId}', this.data.supplierId);
      post(url, {}, false)
          .then(res => {
            // this.showBusiness = true;
            this.business = res.data;
            this.business.supplierDigitalIdentityReportTime = moment(this.business.supplierDigitalIdentityReportTime).format("YYYY-MM-DD HH:mm:ss")

            if (this.business.corporateHonorsImg && this.business.corporateHonorsImg !== '') {
              this.business.list = this.business.corporateHonorsImg.split(",");
              if (this.business.list.length > 0 && this.business.list[this.business.list.length - 1] === '') {
                this.business.list.splice(this.business.list.length - 1, 1)
              }
            }

            // setTimeout(() => {
            //   this.initBusinessMap();// 渲染地图
            // }, 500);

          })
          .catch(err => {
            Toast(err.message);
          })
          .finally(() => {
            this.loadingAll = false;
          })
    },
    onClickShowFarm(farmManagerId) {
      this.loadingAll = true;
      const url = '/api/public/v2/manufacture/tracing/{farmManagerId}/farmManagerInfo'.replace('{farmManagerId}', farmManagerId);
      post(url, {}, false)
          .then(res => {
            // this.vaccinumRecordList = res.data.breedingBatchStageVaccineList;

            this.farm = res.data;

            this.showFarm = true;

            setTimeout(() => {
              this.initFarmMap();// 渲染地图
            }, 500);
          })
          .catch(err => {
            Toast(err.message);
          })
          .finally(() => {
            this.loadingAll = false;
          })
    },
    onClickShowSteps() {
      this.loadingAll = true;
      const url = '/api/public/v2/manufacture/tracing/{breedingBatchId}/findStepInfo'.replace('{breedingBatchId}', this.data.breedingBatchId);
      post(url, {}, false)
          .then(res => {
            this.show = true;

            this.showData = [];

            for (let i = 0; i < res.data.length; i++) {
              this.showData.push({
                stepDate: res.data[i].stepDate,
                当日步数: res.data[i].step
              })
            }

            this.createChart();

          })
          .catch(err => {
            Toast(err.message);
          })
          .finally(() => {
            this.loadingAll = false;
          })
    },
    onClickShowOrderContractImage() {
      this.showOrderContractImage = true;
    },

    getFeedingFrequency(feedingFrequency) {
      if (feedingFrequency) {
        switch (feedingFrequency) {
          case 'EVERYDAY':
            return '每天一次';
          case 'SINGLE_TIME':
            return '单次投喂';
          default:
            return ''
        }
      }
      return '';
    },
    getFeedingTime(feedTime) {
      return moment(feedTime).format('yyyy-MM-DD HH:mm:ss')
      // return moment(startTime).format('yyyy-MM-DD') + "至" + moment(endTime).format('yyyy-MM-DD')
    },
    getTime(startTime) {
      return moment(startTime).format('yyyy-MM-DD HH:mm:ss')
    },
    getExpectBreedingDay(expectBreedingDay) {
      switch (expectBreedingDay) {
        case 'LT_HUNDRED_THIRTY':
          return '100 < D ≤ 130天';
        case 'LT_HUNDRED_FIFTY':
          return '130 < D ≤ 150天';
        case 'LT_HUNDRED_EIGHTY':
          return '150 < D ≤ 180天';
        default:
          return ''
      }
    },

    initFarmMap() {

      let latitude = Number(this.farm.latitude);
      let longitude = Number(this.farm.longitude);

      console.log("initFarmMap = " + latitude + " ; " + longitude);

      if (this.farmMap) {
        this.farmMap.setCenter(new TMap.LatLng(latitude, longitude));
        this.farmMarkerLayer.updateGeometries([
          {
            "styleId": "myStyle",
            "id": "1",
            "position": new TMap.LatLng(latitude, longitude),
            "properties": {//自定义属性
              "title": "marker1"
            }
          }
        ])

        return;
      }

      const center = new TMap.LatLng(latitude, longitude);  //设置地图中心点坐标
      this.farmMap = new TMap.Map("farmMap", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        }
      });

      //创建并初始化MultiMarker
      this.farmMarkerLayer = new TMap.MultiMarker({
        map: this.farmMap,  //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          "myStyle": new TMap.MarkerStyle({
            "width": 25,  // 点标记样式宽度（像素）
            "height": 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            "anchor": {x: 16, y: 32}
          })
        },
        //点标记数据数组
        geometries: [{
          "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          "styleId": 'myStyle',  //指定样式id
          "position": new TMap.LatLng(latitude, longitude),  //点标记坐标位置
          "properties": {//自定义属性
            "title": "marker1"
          }
        }
        ]
      });

    },
    initBusinessMap() {

      let latitude = Number(this.business.latitude);
      let longitude = Number(this.business.longitude);

      console.log("initBusinessMap = " + latitude + " ; " + longitude);

      if (this.businessMap) {
        this.businessMap.center = new TMap.LatLng(latitude, longitude);
        this.businessMarkerLayer.updateGeometries([
          {
            "styleId": "myStyle",
            "id": "111",
            "position": new TMap.LatLng(latitude, longitude),
            "properties": {//自定义属性
              "title": "marker1"
            }
          }
        ])

        return;
      }

      console.log("initBusinessMap = " + latitude + " ; " + longitude);

      const center = new TMap.LatLng(latitude, longitude);  //设置地图中心点坐标
      this.businessMap = new TMap.Map("businessMap", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        }
      });

      //创建并初始化MultiMarker
      this.businessMarkerLayer = new TMap.MultiMarker({
        map: this.businessMap,  //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          "myStyle": new TMap.MarkerStyle({
            "width": 25,  // 点标记样式宽度（像素）
            "height": 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            "anchor": {x: 16, y: 32}
          })
        },
        //点标记数据数组
        geometries: [{
          "id": "111",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          "styleId": 'myStyle',  //指定样式id
          "position": new TMap.LatLng(latitude, longitude),  //点标记坐标位置
          "properties": {//自定义属性
            "title": "marker1"
          }
        }
        ]
      });

    },

  },
}
</script>

<style lang="less" scoped>
#app {
  background: #f4f6f8;

  .header {
    // background: linear-gradient(to right, #46bd53, #33a73e);
    border-radius: 0 0 10px 10px;

    .text {
      padding: 16px 16px 22px;
      background: url('../assets/images/bg-color.png') no-repeat center / cover;
    }

    .info {
      background: #FFF;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      padding: 16px 12px 0 12px;
      margin-top: -10px;
      // margin-bottom: 20px;
      border-radius: 10px;

      .info_top {
        .van-image {
          border-radius: 8px;
          overflow: hidden;
        }
      }

      .info_bottom {
        display: flex;
        background: url('../assets/images/bg-pass.png') no-repeat center / cover;
        // background: linear-gradient(to right, #f7e2cc, #f7d9b7);
        border-radius: 8px;
        margin-top: 12px;
        padding: 16px 12px;
      }

      .threeTab {
        margin-top: 10px;
      }
    }


  }

  .tabContainer {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin: 0 12px 8px 12px;
  }

  .text-bgc {
    background: #ecf6ec;
    padding: 5px 10px;
    border-radius: 19px;
    color: #34AC40;
  }

  .box {
    background: #fff;
    border-radius: 8px;
    padding: 8px;
    margin: 10px 0;

    & + .box {
      margin-top: 16px;
    }

    .title {
      padding-left: 25px;
      position: relative;
      margin-bottom: 8px;

      &::after, &::before {
        content: "";
        display: block;
        width: 14px;
        height: 14px;
        background: #64b65b;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 5px;
        z-index: 2;
      }

      &::after {
        background: #cce8cb;
        z-index: 1;
        left: 4px;
        top: 1px;
      }
    }
  }

}
</style>